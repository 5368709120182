// ./src/main.tsx
import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import store from './store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import i18n from './i18n'; // Import the i18n configuration
import './index.css';

createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter
                future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                }}>
                <I18nextProvider i18n={i18n}>
                    <ErrorBoundary>
                        <Suspense fallback={<div>Loading...</div>}>
                            <App />
                        </Suspense>
                    </ErrorBoundary>
                </I18nextProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);
