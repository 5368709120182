import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Load initial state from localStorage or use an empty array
const loadCartFromStorage = () => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
};

const cartSlice = createSlice({
    name: 'cart',
    initialState: loadCartFromStorage(),
    reducers: {
        addItem: (state, action: PayloadAction<any>) => {
            const existingItem = state.find(
                (item) => item.id === action.payload.id
            );
            if (existingItem) {
                existingItem.quantity += 1;
            } else {
                state.push({ ...action.payload, quantity: 1 });
            }
            // Save updated cart to localStorage
            localStorage.setItem('cart', JSON.stringify(state));
        },
        decrementItem: (state, action: PayloadAction<string>) => {
            const existingItem = state.find((item) => item.id === action.payload);
            if (existingItem) {
                if (existingItem.quantity > 1) {
                    existingItem.quantity -= 1;
                } else {
                    return state.filter((item) => item.id !== action.payload);
                }
            }
            // Save updated cart to localStorage
            localStorage.setItem('cart', JSON.stringify(state));
        },
        removeItem: (state, action: PayloadAction<string>) => {
            const updatedCart = state.filter((item) => item.id !== action.payload);
            // Save updated cart to localStorage
            localStorage.setItem('cart', JSON.stringify(updatedCart));
            return updatedCart;
        },
        clearCart: (state) => {
            localStorage.removeItem('cart'); // Clear cart from localStorage
            return []; // Reset the cart state to an empty array
        },
    },
});

export const { addItem, decrementItem, removeItem, clearCart } = cartSlice.actions;
export default cartSlice.reducer;
