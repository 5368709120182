// src/store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import menuReducer from './menuSlice';
import cartReducer from './cartSlice';
import themeReducer from './themeSlice';
import languageReducer from './languageSlice';
import restaurantReducer from './restaurantSlice';
import locationReducer from './locationSlice';

const store = configureStore({
    reducer: {
        menu: menuReducer,
        cart: cartReducer,
        theme: themeReducer,
        language: languageReducer,
        restaurant: restaurantReducer,
        location: locationReducer,
    },
});

export default store;
