import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string | null;
}

interface Restaurant {
    id: string;
    name: string;
    logoUrl: string | null;
    supportedLanguages: string[];
    translations: Translation[];
}

interface RestaurantState {
    data: Restaurant | null;
}

const initialState: RestaurantState = {
    data: JSON.parse(localStorage.getItem('restaurantData') || 'null'), // Load from localStorage
};

const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState,
    reducers: {
        updateRestaurant(state, action: PayloadAction<Restaurant>) {
            state.data = action.payload;
            localStorage.setItem('restaurantData', JSON.stringify(action.payload)); // Persist to localStorage
        },
    },
});

export const { updateRestaurant } = restaurantSlice.actions;
export default restaurantSlice.reducer;
