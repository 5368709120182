// File: src/utils/order-status.utils.ts

import { ChipProps } from '@material-tailwind/react';
import { OrderStatus } from '../types';

export const statusBadgeColor = (status: OrderStatus): ChipProps['color'] => {
    switch (status) {
        case 'pending':
            return 'yellow';
        case 'accepted':
        case 'preparing':
            return 'blue';
        case 'ready':
        case 'completed':
            return 'green';
        case 'cancelled':
            return 'red';
        default:
            return 'gray';
    }
};

export const statusOptions = [
    { label: 'Pending', value: 'pending', color: 'yellow' },
    { label: 'Accepted', value: 'accepted', color: 'blue' },
    { label: 'Preparing', value: 'preparing', color: 'blue' },
    { label: 'Ready', value: 'ready', color: 'green' },
    { label: 'Completed', value: 'completed', color: 'green' },
    { label: 'Cancelled', value: 'cancelled', color: 'red' },
] as const;
