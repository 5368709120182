import {updateLocation} from "./locationSlice.ts";

const API_BASE_URL = import.meta.env.VITE_API_URL;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { updateRestaurant } from './restaurantSlice';

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string | null;
}

interface MenuItem {
    id: string;
    restaurantId: string;
    name: string | null;
    description: string | null;
    price: string;
    imageUrl: string | null;
    isAvailable: boolean;
    translations: Translation[];
}

interface Category {
    id: string;
    restaurantId: string;
    order: number;
    menuItems: MenuItem[];
    translations: Translation[];
}

interface Menu {
    id: string;
    restaurantId: string;
    name: string | null;
    description: string | null;
    isActive: boolean;
    categories: Category[];
    translations: Translation[];
}

interface MenuState {
    data: Menu | null;
    loading: boolean;
    error: string | null;
}

const initialState: MenuState = {
    data: null,
    loading: false,
    error: null,
};

export const fetchMenu = createAsyncThunk(
    'menu/fetchMenu',
    async (locationId: string, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/locations/${locationId}/menu`);
            const menuData = response.data;

            dispatch(updateLocation(menuData.location));

            return menuData;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || 'Failed to fetch menu.');
        }
    }
);

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMenu.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchMenu.fulfilled, (state, action) => {
                state.loading = false;
                delete action.payload.restaurant;
                state.data = action.payload;
            })
            .addCase(fetchMenu.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export default menuSlice.reducer;
