// File: src/store/languageSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
    current: string;
    languages: string[];
}

const initialState: LanguageState = {
    current: 'en', // Default language
    languages: [
        'en',
        'pl'
    ],
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setCurrentLanguage(state, action: PayloadAction<string>) {
            state.current = action.payload;
        },
        setSupportedLanguages(state, action: PayloadAction<string[]>) {
            state.languages = action.payload;
        }
    },
});

export const { setCurrentLanguage, setSupportedLanguages } = languageSlice.actions;
export default languageSlice.reducer;
