// src/App.tsx
import {Routes, Route} from 'react-router-dom';
import Menu from './pages/Menu';
import Cart from './pages/Cart';
import NotFound from './pages/NotFound';
import Order from "./pages/Order.tsx";
import OrdersHistory from "./pages/OrdersHistory.tsx";

const App = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path="/:id" element={<Menu/>}/>
                <Route path="/:id/cart" element={<Cart/>}/>
                <Route path="/:id/orders/:orderId" element={<Order />} />
                <Route path="/orders" element={<OrdersHistory />} />
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </ScrollToTop>
    );
};

import "./App.css";
import ScrollToTop from "./components/ScrollToTop.tsx";
export default App;
