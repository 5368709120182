import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../store/themeSlice';
import { setCurrentLanguage } from '../store/languageSlice';
import { Typography, Button, Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    SunIcon,
    MoonIcon,
    ArrowLeftCircleIcon,
    ShoppingCartIcon,
    TableCellsIcon,
    Squares2X2Icon,
    ClipboardIcon,
    GlobeAltIcon,
} from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../store';
import { useIsMobile } from '../hooks/useIsMobile';
import {LANGUAGE_FLAGS} from "../utils/translations.utils.tsx";
import Price from "./Price.tsx";

const BREAKPOINT_LG = 1024;
const DEFAULT_LANGUAGE = 'en';
interface HeaderProps {
    totalItems: number;
    totalPrice: string;
    viewMode?: 'list' | 'card';
    toggleViewMode?: (mode: 'list' | 'card') => void;
    showCart?: boolean;
}

const Header: React.FC<HeaderProps> = ({
                                           totalItems,
                                           totalPrice,
                                           viewMode,
                                           toggleViewMode,
                                            showCart = true
                                       }) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const isCartPage = location.pathname.includes('cart');

    const theme = useSelector((state: RootState) => state.theme.mode);
    const locationData = useSelector((state: RootState) => state.location.data);

    const isLight = theme === 'light';

    const languages = useMemo(() => {
        if (!locationData.restaurant?.supportedLanguages?.length) return [];

        return locationData.restaurant.supportedLanguages.map((lang) => ({
            code: lang,
            name: t(`language.${lang}`, { defaultValue: lang.toUpperCase() }),
            flag: `https://flagcdn.com/w40/${LANGUAGE_FLAGS[lang] || lang}.png`,
        }));
    }, [locationData.restaurant?.supportedLanguages, t]);

    useEffect(() => {
        if (locationData.restaurant?.supportedLanguages?.length) {
            const savedLanguage = localStorage.getItem('language') || DEFAULT_LANGUAGE;
            const validLanguage = locationData.restaurant.supportedLanguages.includes(savedLanguage)
                ? savedLanguage
                : locationData.restaurant.supportedLanguages[0];

            i18n.changeLanguage(validLanguage);
            dispatch(setCurrentLanguage(validLanguage));
            localStorage.setItem('language', validLanguage);
        }
    }, [locationData.restaurant, dispatch, i18n]);

    useEffect(() => {
        const customerId = localStorage.getItem('customerId') || uuidv4();
        localStorage.setItem('customerId', customerId);
    }, [id]);

    useEffect(() => {
        document.body.style.backgroundColor = isLight ? 'rgb(249 250 251)' : '#333333';
    }, [isLight]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        dispatch(setCurrentLanguage(lang));
        localStorage.setItem('language', lang);
        window.location.reload(); // Refresh to update language-specific API responses
    };

    const lastVisitedLocation = localStorage.getItem('lastVisitedLocation');

    return (
        <>
            <div
                className={`sticky top-0 z-10 p-4 flex justify-between items-center ${
                    isLight ? 'bg-white shadow-md text-neutralDark' : 'bg-neutral-700 text-neutralLight shadow-md'
                }`}
            >
                <div className="flex items-center">
                    <Link to={`/${locationData.id || ''}`}>
                        <img
                            src={locationData.restaurant?.logoUrl || '/no-logo.png'}
                            alt={locationData.restaurant?.name || 'Restaurant Logo'}
                            className="h-16 w-16 rounded-full mr-4 object-cover"
                        />
                    </Link>
                    <Typography variant="h4" className="font-bold hidden lg:flex">
                        {locationData.restaurant?.name || t('restaurant')}
                    </Typography>
                </div>

                <div className="flex flex-row gap-4 items-center">
                    {!isCartPage && toggleViewMode && viewMode && !isMobile && (
                        <Button
                            onClick={() => toggleViewMode(viewMode === 'list' ? 'card' : 'list')}
                            variant="text"
                            className={`flex items-center ${
                                isLight ? 'text-neutralLight hover:text-blue-500' : 'text-neutralLight hover:text-blue-300'
                            }`}
                        >
                            {viewMode === 'list' ? (
                                <Squares2X2Icon className="h-6 w-6" />
                            ) : (
                                <TableCellsIcon className="h-6 w-6" />
                            )}
                        </Button>
                    )}

                    {languages.length > 0 && (
                        <Menu>
                            <MenuHandler>
                                <Button
                                    variant="text"
                                    className={`gap-2 flex items-center ${
                                        isLight ? 'text-neutralLight hover:text-blue-500' : 'text-neutralLight hover:text-blue-300'
                                    }`}
                                >
                                    <GlobeAltIcon className="h-6 w-6" />
                                    {!isMobile && (
                                        <span>
                                            {languages.find((lang) => lang.code === i18n.language)?.name ||
                                                t('language')}
                                        </span>
                                    )}
                                </Button>
                            </MenuHandler>
                            <MenuList
                                className={`rounded-lg p-0 mt-2 shadow-md ${
                                    isLight ? 'bg-gray-100 text-neutralDark' : 'bg-neutral-800 text-neutralLight'
                                }`}
                            >
                                {languages.map((lang) => (
                                    <MenuItem
                                        key={lang.code}
                                        onClick={() => handleLanguageChange(lang.code)}
                                        className="gap-2 flex items-center rounded-md hover:bg-blue-500 hover:text-white"
                                    >
                                        <img src={lang.flag} alt={lang.name} className="h-6 w-8 rounded-sm" />
                                        <span className="text-left text-neutralLight">{lang.name}</span>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>
                    )}

                    <Link to="/orders">
                        <Button
                            variant="text"
                            className={`flex items-center ${
                                isLight ? 'text-neutralLight hover:text-blue-500' : 'text-neutralLight hover:text-blue-300'
                            }`}
                        >
                            <ClipboardIcon className="h-6 w-6 mr-2" />
                            <span className="hidden lg:flex">{t('orders')}</span>
                        </Button>
                    </Link>

                    <Button
                        onClick={() => dispatch(toggleTheme())}
                        variant="text"
                        className={`flex items-center ${
                            isLight ? 'text-neutralLight hover:text-blue-500' : 'text-neutralLight hover:text-blue-300'
                        }`}
                    >
                        {isLight ? <MoonIcon className="h-6 w-6" /> : <SunIcon className="h-6 w-6" />}
                    </Button>
                </div>
            </div>

            {totalItems > 0 && (
                <div
                    className={`fixed bottom-0 left-0 right-0 p-4 flex justify-between items-center z-10 ${
                        isLight ? 'bg-gray-200 text-neutralDark' : 'bg-neutral-800 text-neutralLight `'
                    } shadow-md`}
                >
                    {showCart ? (
                        <>
                            <Typography className="flex items-center">
                                <ShoppingCartIcon className="h-6 w-6 mr-2 hidden lg:flex" />
                                <span className={`mr-4`}>{t('products', { count: totalItems })}</span> <Price item={{
                                        price: totalPrice,
                                        currencySymbol: locationData.restaurant?.currencySymbol,
                                        currencySymbolPosition: locationData.restaurant?.currencySymbolPosition,
                                    }} />
                            </Typography>

                            <Link to={`/${id}/cart`}>
                                <Button className="flex items-center gap-2" color={isLight ? 'blue' : 'gray'} variant="gradient">
                                    <ShoppingCartIcon className="h-6 w-6" />
                                    {t('viewCart')}
                                </Button>
                            </Link>
                        </>
                    ) : (
                        <Link to={`/${id}`}>
                            <Button className="flex items-center gap-2" color={isLight ? 'blue' : 'gray'} variant="gradient">
                                <ArrowLeftCircleIcon className="h-6 w-6" />
                                {t('backToMenu')}
                            </Button>
                        </Link>
                    )}
                </div>
            )}
        </>
    );
};

export default Header;
