// File: src/pages/Menu.tsx

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMenu } from '../store/menuSlice';
import MenuItem from '../components/MenuItem';
import Header from '../components/Header';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Alert } from '@material-tailwind/react';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';

interface Translation {
    id: string;
    language: string;
    name: string;
    description: string | null;
}

interface MenuItemType {
    id: string;
    price: string;
    isAvailable: boolean;
    translations: Translation[];
    imageUrl: string | null;
}

interface Category {
    id: string;
    order: number;
    translations: Translation[];
    menuItems: MenuItemType[];
}

interface MenuData {
    location: {
        restaurant: {
            id: string;
            name: string;
            logoUrl: string | null;
            currencySymbol: string;
            currencySymbolPosition: string;
            translations: Translation[];
        };
    };
    menu: {
        id: string;
        isActive: boolean;
        translations: Translation[];
        categories: Category[];
    };
}

const Menu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const menu = useSelector((state: any) => state.menu);
    const cart = useSelector((state: any) => state.cart);
    const theme = useSelector((state: any) => state.theme.mode);
    const currentLanguage = useSelector((state: RootState) => state.language.current);

    const { t } = useTranslation();

    const [viewMode, setViewMode] = useState<'list' | 'card'>(
        () => (localStorage.getItem('viewMode') as 'list' | 'card') || 'card'
    );

    const [activeCategory, setActiveCategory] = useState<Category | null>(null);

    const getTranslation = (
        translations: Translation[] | undefined,
        field: 'name' | 'description',
        defaultValue: string = ''
    ) => {
        if (!translations) return defaultValue;

        // Normalize currentLanguage to lowercase for case-insensitive matching
        const normalizedCurrentLanguage = currentLanguage.toLowerCase();

        // Attempt to match exact language code
        let translation = translations.find(
            (t) => t.language.toLowerCase() === normalizedCurrentLanguage
        );

        // If not found, try to match primary language subtag (first two letters)
        if (!translation && normalizedCurrentLanguage.length > 2) {
            const primaryLang = normalizedCurrentLanguage.slice(0, 2);
            translation = translations.find(
                (t) => t.language.toLowerCase() === primaryLang
            );
        }

        // If still not found, fallback to English
        if (!translation) {
            const enTranslation = translations.find(
                (t) => t.language.toLowerCase() === 'en'
            );
            return enTranslation?.[field] || defaultValue;
        }

        return translation[field] || defaultValue;
    };

    // Define sortedCategories using useMemo
    const sortedCategories = useMemo(() => [
        {
            id: 'all',
            translations: [], // No need for translations here
            order: -1,
            menuItems: [] // 'All' category might not have its own items
        },
        ...(menu.data?.menu.categories
            ? [...menu.data.menu.categories].sort((a: Category, b: Category) => a.order - b.order)
            : [])
    ], [menu.data]);

    useEffect(() => {
        if (id) {
            dispatch(fetchMenu(id));
        } else {
            navigate('/404');
        }
    }, [dispatch, id, navigate]);

    useEffect(() => {
        if (menu.data && menu.data.menu.categories.length > 0) {
            setActiveCategory(sortedCategories[0]); // Set to 'all' category
        }
    }, [menu.data, sortedCategories]);

    const toggleViewMode = (mode: 'list' | 'card') => {
        setViewMode(mode);
        localStorage.setItem('viewMode', mode);
    };

    const handleCategoryClick = (category: Category) => {
        setActiveCategory(category);
    };

    const totalItems = cart.reduce(
        (total: number, item: any) => total + item.quantity,
        0
    );

    const totalPrice = cart
        .reduce(
            (total: number, item: any) =>
                total + parseFloat(item.price) * item.quantity,
            0
        )
        .toFixed(2);

    if (menu.loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
            </div>
        );
    }

    if (!menu.data) return null;

    const menuData: MenuData = menu.data;

    const displayCategories =
        activeCategory && activeCategory.id === 'all'
            ? sortedCategories.filter((cat) => cat.id !== 'all')
            : activeCategory
                ? [activeCategory]
                : [];

    return (
        <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
            <Header
                totalItems={totalItems}
                totalPrice={totalPrice}
                viewMode={viewMode}
                toggleViewMode={toggleViewMode}
            />

            <div className="container mx-auto p-4 pb-20">
                {!menuData.menu.isActive && (
                    <Alert color="red" className="mb-4">
                        {t('menuInactive')}
                    </Alert>
                )}

                {/* Category Tabs */}
                <div className="mb-8 lg:flex-wrap gap-4 flex lg:overflow-visible overflow-x-auto scrollbar-hide">
                    {sortedCategories.map((category: any) => (
                        <button
                            key={category.id}
                            className={`whitespace-nowrap px-6 py-2 rounded-xl flex-shrink-0
                                ${activeCategory && activeCategory.id === category.id
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200 text-gray-800'
                            }`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {category.id === 'all'
                                ? t('allItems')
                                : getTranslation(
                                    category.translations,
                                    'name',
                                    `Category ${category.order + 1}`
                                )
                            }
                        </button>
                    ))}
                </div>

                {/* Menu Items */}
                <div className="space-y-8 mb-12">
                    {displayCategories.map((category: any) => (
                        <div key={category.id} className="mb-6">
                            {/* Display Category Name */}
                            {activeCategory?.id === 'all' && (
                                <Typography
                                    variant="h3"
                                    className={`my-4 text-center lg:text-left ${
                                        theme === 'dark' ? 'text-neutralLight' : 'text-neutralDark'
                                    }`}
                                >
                                    {getTranslation(
                                        category.translations,
                                        'name',
                                        `Category ${category.order + 1}`
                                    )}
                                </Typography>
                            )}
                            <div
                                className={`${
                                    viewMode === 'list'
                                        ? 'flex flex-col space-y-4'
                                        : 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6'
                                }`}
                            >
                                {category.menuItems?.map((item: any) => (
                                    <MenuItem
                                        key={item.id}
                                        item={{
                                            ...item,
                                            currencySymbol: menuData.location.restaurant.currencySymbol,
                                            currencySymbolPosition: menuData.location.restaurant.currencySymbolPosition,
                                            name: getTranslation(item.translations, 'name', ''),
                                            description: getTranslation(item.translations, 'description', ''),
                                        }}
                                        viewMode={viewMode}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Menu;
