import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Typography, Button, Chip} from '@material-tailwind/react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import {paymentMethodBadgeColor, paymentMethodIcon} from "../utils/payment-menthod.utils.tsx";
import {paymentStatusColor} from "../utils/payment-status.utils.tsx";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import Price from "../components/Price.tsx";

const OrdersHistory = () => {
    const theme = useSelector((state: any) => state.theme.mode);
    const customerId = localStorage.getItem('customerId');
    const API_BASE_URL = import.meta.env.VITE_API_URL;
    const { t, i18n } = useTranslation();

    const [orders, setOrders] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const restaurant = useSelector((state: any) => state.location.data.restaurant);

    useEffect(() => {
        const fetchOrders = async () => {
            if (!customerId) {
                setError('Customer ID not found. Please log in.');
                setLoading(false);
                return;
            }
            try {
                const response = await axios.get(`${API_BASE_URL}/customer/${customerId}/orders`);
                setOrders(response.data);
            } catch (err) {
                setError('Failed to fetch orders. Please try again.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, [customerId]);

    if (loading) {
        return (
            <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Typography className="text-center py-20">Loading...</Typography>
            </div>
        );
    }

    if (error) {
        return (
            <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Typography className="text-center py-20 text-red-500">{error}</Typography>
            </div>
        );
    }

    if (orders.length === 0) {
        return (
            <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Header
                    logoUrl={null}
                    restaurantName={t('ordersHistory')}
                    totalItems={0}
                    totalPrice="0.00"
                />
                <div className="container mx-auto p-6">
                    <Typography
                        className={`text-center py-20 text-lg ${
                            theme === 'dark' ? 'text-neutralLight' : 'text-neutralDark'
                        }`}
                    >
                        {
                            t('noOrdersFound')
                        }
                    </Typography>
                </div>
            </div>
        );
    }

    return (
        <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
            <Header
                logoUrl={null}
                restaurantName={t('ordersHistory')}
                totalItems={0}
                totalPrice="0.00"
            />
            <div className="container mx-auto p-6 space-y-6">
                {orders.map((order) => (
                    <div
                        key={order.id}
                        className={`p-6 rounded-lg shadow-md ${
                            theme === 'dark' ? 'bg-neutral-700 text-neutralLight' : 'bg-white text-neutralDark'
                        }`}
                    >
                        {/* Header */}
                        <div className="flex items-center justify-between mb-4">
                            <Link to={`/${order.locationId}/orders/${order.id}`} className="text-primary">
                                <Typography variant="h4" className="font-bold">
                                    #{order.orderNumber}
                                </Typography>
                            </Link>

                            <Typography>
                                {dayjs(order.createdAt).fromNow()}
                            </Typography>
                        </div>

                        {/* Details */}
                        <div className="lg:flex lg:flex-row justify-between items-center align-middle">
                            <Typography>
                                <span className="font-medium">{t('restaurant')}</span>{': '}
                                <span className="font-semibold">{order.restaurantName}</span>
                            </Typography>

                            <Typography>
                                <span className="font-medium">{t('location')}</span>{': '}
                                <span className="font-semibold">{order.locationName}</span>
                            </Typography>

                            <Typography className={`flex gap-2`}>
                                <span className="font-medium">{t('totalPrice')}{': '}</span>
                                <Price item={{ price: order.totalAmount, currencySymbol: restaurant.currencySymbol, currencySymbolPosition: restaurant.currencySymbolPosition }} />
                            </Typography>

                            <div className="chips flex flex-col lg:flex-row gap-4 my-6 lg:my-0 items-center">
                                <Chip
                                    value={`${t(order.paymentStatus)}`}
                                    color={paymentStatusColor(order.paymentStatus)}
                                    className="text-sm font-semibold cursor-pointer text-center w-full max-w-64"
                                />

                                <Chip
                                    value={t(order.paymentMethod)}
                                    color={paymentMethodBadgeColor(order.paymentMethod)}
                                    icon={paymentMethodIcon(order.paymentMethod)}
                                    className="text-sm font-semibold cursor-pointer text-center w-full max-w-64"
                                />
                            </div>

                            <div className={`flex lg:hidden justify-center`}>
                                <Link to={`/${order.locationId}/orders/${order.id}`}>
                                    <Button color="blue">
                                        {t('viewOrder')}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OrdersHistory;
