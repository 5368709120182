import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    state: State = { hasError: false };

    static getDerivedStateFromError(): State {
        // Update state to display fallback UI on error
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // Log the error to the console or a monitoring service
        console.error('ErrorBoundary caught an error:', error, info);
    }

    render() {
        if (this.state.hasError) {
            // Render fallback UI
            return (
                <div className="min-h-screen flex flex-col items-center justify-center text-center">
                    <h1 className="text-2xl font-bold mb-4">Something went wrong.</h1>
                    <p className="text-gray-500">Please try refreshing the page.</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
