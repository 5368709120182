// locationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Location {
    id: string;
    restaurantId: string;
    name: string;
    address: string;
    phoneNumber: string;
    activeMenuId: string;
    createdAt: string;
    updatedAt: string;
}

interface LocationState {
    data: Location | null;
}

// Funkcja do bezpiecznego parsowania JSON
function safeJsonParse<T>(data: string | null): T | null {
    try {
        return data ? JSON.parse(data) : null;
    } catch (error) {
        console.error(`Error parsing JSON data: ${error}`);
        localStorage.removeItem('locationData'); // Usunięcie nieprawidłowych danych
        return null;
    }
}

// Inicjalny stan z użyciem bezpiecznego parsowania
const initialState: LocationState = {
    data: safeJsonParse<Location>(localStorage.getItem('locationData')),
};

const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        updateLocation(state, action: PayloadAction<Location>) {
            state.data = action.payload;
            try {
                localStorage.setItem('locationData', JSON.stringify(action.payload));
            } catch (error) {
                console.error(`Error saving location data to localStorage: ${error}`);
            }
        },
    },
});

export const { updateLocation } = locationSlice.actions;
export default locationSlice.reducer;
