import {MenuItem} from "../types";

interface PriceTagProps {
    item: Record<string, any>
}

const PriceTag: React.FC<PriceTagProps> = ({ item, style = `font-bold text-lg` }) => {
    const { price, currencySymbol, currencySymbolPosition } = item;

    const formattedPrice = currencySymbolPosition === 'before'
        ? `${currencySymbol}${price}`
        : `${price} ${currencySymbol}`;

    return (
        <div className={style}>
            {formattedPrice}
        </div>
    );
};

export default PriceTag;
