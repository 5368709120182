import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Chip, Tooltip } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { statusBadgeColor } from "../utils/order-status.utils";
import { paymentStatusColor } from "../utils/payment-status.utils";
import { paymentMethodBadgeColor, paymentMethodIcon } from "../utils/payment-menthod.utils";
import { useTranslation } from "react-i18next";
import Price from "../components/Price.tsx";

dayjs.extend(relativeTime);

const Order = () => {
    const { orderId } = useParams<{ orderId: string }>();
    const theme = useSelector((state: any) => state.theme.mode);

    const [order, setOrder] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const API_BASE_URL = import.meta.env.VITE_API_URL;

    const { t } = useTranslation();

    const isDarkMode = theme === 'dark';
    const containerClass = `p-6 rounded-lg shadow-md ${isDarkMode ? 'bg-neutral-700 text-neutralLight' : 'bg-white text-neutralDark'}`;
    const itemClass = `flex justify-between items-center p-4 rounded-lg flex-col lg:flex-row  ${!isDarkMode ? 'bg-neutralLight text-neutralDark' : 'bg-neutralDark text-neutralLight'}`;
    const restaurant = useSelector((state: any) => state.location.data.restaurant);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/orders/${orderId}`);
                setOrder(response.data);
            } catch (err) {
                setError(t('submittedFailure'));
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        if (orderId) {
            fetchOrder();
        } else {
            setError(t('orderNotProvided'));
            setLoading(false);
        }
    }, [orderId, t]);

    if (loading) {
        return (
            <div className={`min-h-screen ${isDarkMode ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Typography className="text-center py-20">{t('loading')}</Typography>
            </div>
        );
    }

    if (error) {
        return (
            <div className={`min-h-screen ${isDarkMode ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Typography className="text-center py-20 text-red-500">{error}</Typography>
            </div>
        );
    }

    if (!order) {
        return (
            <div className={`min-h-screen ${isDarkMode ? 'bg-neutralDark' : 'bg-gray-50'}`}>
                <Typography className="text-center py-20 text-gray-500">
                    {t('noOrdersFound')}
                </Typography>
            </div>
        );
    }

    const isLight = theme === 'light';

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-neutralDark' : 'bg-gray-50'}`}>
            <Header
                totalItems={order.orderItems.length}
                totalPrice={order.totalAmount}
                showCart={false}
            />

            <div className="container mx-auto p-4">
                {/* Order Details */}
                <div className={`${containerClass} mb-6`}>
                    <div className="flex justify-between">
                        <Typography variant="h5" className="font-bold mb-4">
                            #{order.orderNumber}
                        </Typography>
                        <Tooltip content={dayjs(order.createdAt).format('YYYY/MM/DD HH:mm')} placement="top">
                            <Typography className="mb-5 lg:mb-0 cursor-pointer">
                                {dayjs(order.createdAt).fromNow()}
                            </Typography>
                        </Tooltip>
                    </div>

                    <div className="flex items-center space-x-4 mt-2 justify-end flex-col lg:flex-row">
                        <div className="chips flex flex-col lg:flex-row gap-4">
                            <Chip
                                value={`${t('status')}: ${t(order.status)}`}
                                className="text-sm font-semibold"
                                color={statusBadgeColor(order.status)}
                            />
                            <Chip
                                value={t(order.paymentStatus)}
                                color={paymentStatusColor(order.paymentStatus)}
                                className="text-sm font-semibold"
                            />
                            <Chip
                                value={t(order.paymentMethod)}
                                color={paymentMethodBadgeColor(order.paymentMethod)}
                                icon={paymentMethodIcon(order.paymentMethod)}
                                className="text-sm font-semibold"
                            />
                        </div>
                    </div>

                    <Typography className="text-left mt-5">
                        {order.customerNotes || t('noNoteProvided')}
                    </Typography>
                </div>

                {/* Order Items */}
                <div className={`${containerClass} mb-20`}>
                    <Typography variant="h5" className="font-bold mb-4 text-left">
                        {t('items')}
                    </Typography>
                    <div className="space-y-4">
                        {order.orderItems.map((item: any) => (

                            <div key={item.id} className={itemClass}>
                                <div className={`flex items-center lg:flex-row flex-col space-x-4 w-full`}>
                                    {item.menuItem.imageUrl && (
                                        <img
                                            src={item.menuItem.imageUrl}
                                            alt={item.name}
                                            className="h-32 w-full lg:h-24 lg:max-w-32 rounded object-cover"
                                            onError={(e) => ((e.target as HTMLImageElement).src = '/no-image.png')}
                                        />
                                    )}
                                    <div className="w-full text-left flex flex-col">
                                        <Typography variant="h5" className="font-bold mt-4 lg:mt-0">
                                            {item.name}
                                        </Typography>
                                        <Typography>
                                            {item.menuItem.description}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-6 justify-end w-full lg:w-1/6">
                                    <Typography className="font-bold text-lg">
                                        <Price item={{ price: item.price, currencySymbol: restaurant.currencySymbol, currencySymbolPosition: restaurant.currencySymbolPosition }} />
                                    </Typography>
                                    <Typography className="text-lg font-semibold">
                                        x{item.quantity}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end mt-6">
                        <Typography variant="h5" className="font-bold flex gap-2">
                            <span>{t('total')}:</span> <Price item={{ price: order.totalAmount, currencySymbol: restaurant.currencySymbol, currencySymbolPosition: restaurant.currencySymbolPosition }} />
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Order;
