// File: src/utils/translations.utils.ts
export const LANGUAGE_FLAGS: Record<string, string> = {
    en: 'us',
    pl: 'pl',
    es: 'es',
    fr: 'fr',
    de: 'de',
    it: 'it',
    jp: 'jp',
};
