// File: src/components/MenuItem.tsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, decrementItem, removeItem } from '../store/cartSlice';
import { Typography, Button, IconButton } from '@material-tailwind/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from "react-i18next";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid";
import Price from "./Price.tsx";

const MenuItem = ({ item, viewMode }: { item: any; viewMode: string }) => {
    const dispatch = useDispatch();

    // Get theme and cart state from Redux
    const theme = useSelector((state: any) => state.theme.mode);
    const cart = useSelector((state: any) => state.cart);
    const isLight = theme === 'light';
    const isListMode = viewMode === 'list';
    const { t } = useTranslation();

    // Find the item in the cart
    const cartItem = cart.find((cartItem: any) => cartItem.id === item.id);

    // Handlers for cart operations
    const handleAddToCart = () => dispatch(addItem(item));

    const handleQuantityChange = (change: number) => {
        if (change > 0) {
            dispatch(addItem(item));
        } else {
            if (cartItem && cartItem.quantity + change <= 0) {
                dispatch(removeItem(item.id));
            } else {
                dispatch(decrementItem(item.id));
            }
        }
    };

    const API_BASE_URL = import.meta.env.VITE_API_URL;
    const currentLanguage = useSelector((state: any) => state.language.current);
    const currentItem = item.translations.find((t: any) => t.language === currentLanguage) ? item.translations.find((t: any) => t.language === currentLanguage) : item.translations.find((t: any) => t.language === 'en')

    return (
        <div
            className={`flex justify-between rounded-lg p-4 shadow-xl 
                ${isLight ? 'bg-neutralLight text-neutralDark' : 'bg-neutralDark text-neutralLight'} 
                ${isListMode ? 'flex-row gap-8' : 'flex-col'
            }`}
            style={{ width: '100%' }}
        >
            <div className={`mb-4 flex ${isListMode ? 'flex-row gap-8' : 'flex-col'}`}>
                <div className="flex justify-center items-center mb-4 h-full min-w-64">

                    {item.imageUrl ? (
                        <img
                            src={`${item.imageUrl}`}
                            alt={currentItem.name}
                            className={`rounded shadow-lg ${isListMode ? 'h-48 w-72' : 'h-48 w-full'}`}
                        />
                    ) : (
                        <div
                            className={`h-48 rounded bg-gray-200 flex items-center justify-center shadow-lg ${isListMode ? 'w-72' : 'w-full'}`} >
                            <Typography variant="body2" className="text-gray-500">
                                {t('noImage')}
                            </Typography>
                        </div>
                    )}
                </div>

                {/* Item Details */}
                <div className="details flex flex-col justify-between text-left">
                    <Typography variant="h4" className="mb-2">
                        {currentItem.name}
                    </Typography>
                    <Typography className="mb-2">{currentItem.description}</Typography>
                    <Typography className="font-bold mb-4"><Price item={item} /></Typography>
                </div>
            </div>

            <div className="summary">
                {/* Add to Cart or Quantity Controls */}
                {cartItem ? (
                    <div className="flex items-center space-x-2 justify-center">

                        <IconButton
                            variant="text"
                            size="sm"
                            onClick={() => handleQuantityChange(-1)}
                            className={`text-neutralLight ${theme === 'light' ? 'hover:text-neutralDark' : 'hover:text-neutralLight'}`}
                        >
                            {cartItem.quantity === 1 ? (
                                <TrashIcon className="h-4 w-4" />
                            ) : (
                                <MinusIcon className="h-4 w-4" />
                            )}
                        </IconButton>

                        <Typography className="text-lg font-semibold w-8 text-center">
                            {cartItem.quantity}
                        </Typography>

                        <IconButton
                            variant="text"
                            size="sm"
                            onClick={() => handleQuantityChange(1)}
                            className={`text-neutralLight ${theme === 'light' ? 'hover:text-neutralDark' : 'hover:text-neutralLight'}`}
                        >
                            <PlusIcon className="h-4 w-4" />
                        </IconButton>
                    </div>
                ) : (
                    <Button
                        onClick={handleAddToCart}
                        variant="gradient"
                        color={isLight ? 'blue' : 'gray'}
                        className={`flex items-center w-full ${isListMode ? '' : 'justify-center'}`}
                    >
                        <PlusCircleIcon className="h-6 w-6 mr-2" />
                        {t('addToCart')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default MenuItem;
