// src/pages/NotFound.tsx
import React from 'react';
import { Typography } from '@material-tailwind/react';

const NotFound = () => (
    <div className="container mx-auto p-4">
        <Typography variant="h3">404 - Page Not Found</Typography>
        <Typography>The page you are looking for does not exist.</Typography>
    </div>
);

export default NotFound;
