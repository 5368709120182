// File: src/pages/Cart.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Textarea, Card, CardBody } from '@material-tailwind/react';
import Header from '../components/Header';
import CartItem from '../components/CartItem';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { RootState } from '../store';
import { Restaurant, Translation } from "../types";
import axios from "axios";
import { clearCart } from "../store/cartSlice";
import Price from "../components/Price.tsx";

interface OrderItem {
    id: string;
    name: string;
    description?: string;
    price: number;
    quantity: number;
    translations: Translation[];
}

interface OrderData {
    restaurantId: string;
    locationId: string;
    items: OrderItem[];
    totalPrice: number;
    note: string;
    customerId: string;
    language: string;
}

const Cart: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const cart = useSelector((state: RootState) => state.cart);
    const theme = useSelector((state: RootState) => state.theme.mode);
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [note, setNote] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [restaurant, setRestaurant] = useState<Restaurant | null>(null);

    const totalPrice = cart
        .reduce(
            (total: number, item) => total + parseFloat(item.price) * item.quantity,
            0
        )
        .toFixed(2);

    const totalItems = cart.reduce(
        (total: number, item) => total + item.quantity,
        0
    );
    const BASE_API_URL = import.meta.env.VITE_API_URL;

    const restaurantId = useSelector((state: RootState) => state.location.data.restaurantId);

    // Fetch restaurant data
    useEffect(() => {
        const fetchRestaurant = async () => {
            if (!id || !cart.length) return;

            try {
                const response = await axios.get(`/restaurants/${restaurantId}`);
                setRestaurant(response.data);
            } catch (err) {
                console.error('Error fetching restaurant:', err);
                setError(t('failedToLoadRestaurant'));
            }
        };

        fetchRestaurant();
    }, [id, cart, t, restaurantId]);

    const submitOrder = async () => {
        if (isSubmitting || !id) return;
        setIsSubmitting(true);
        setError(null);

        const customerId = localStorage.getItem('customerId') || uuidv4();
        if (!customerId) {
            localStorage.setItem('customerId', customerId);
        }

        const orderData: OrderData = {
            restaurantId,
            locationId: id,
            language: currentLanguage,
            items: cart.map(item => ({
                id: item.id,
                name: getTranslation(item.translations, 'name', item.name),
                description: getTranslation(item.translations, 'description', item.description || ''),
                price: parseFloat(item.price),
                quantity: item.quantity,
                translations: item.translations,
            })),
            totalPrice: parseFloat(totalPrice),
            note: note.trim(),
            customerId,
        };

        try {
            const response = await axios.post(`${BASE_API_URL}/orders`, orderData);
            dispatch(clearCart()); // Clear the cart state

            navigate(`/${id}/orders/${response.data.id}`);
        } catch (err: any) {
            console.error('Failed to submit order:', err);
            setError(err.response?.data?.message || t('orderSubmissionFailed'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const locationData = useSelector((state: RootState) => state.location.data);

    const getTranslation = (translations: Translation[] | undefined, field: 'name' | 'description', defaultValue: string) => {
        if (!translations) return defaultValue;
        const translation = translations.find(t => t.language === currentLanguage);
        if (!translation) {
            const enTranslation = translations.find(t => t.language === 'en');
            return enTranslation?.[field] || defaultValue;
        }
        return translation[field] || defaultValue;
    };

    return (
        <div className={`min-h-screen ${theme === 'dark' ? 'bg-neutralDark' : 'bg-gray-50'}`}>
            <Header
                totalItems={totalItems}
                totalPrice={totalPrice}
                showCart={false}
            />

            <div className="container mx-auto p-6 pb-24">
                {error && (
                    <div className="bg-red-50 text-red-700 p-4 rounded-lg mb-4">
                        {error}
                    </div>
                )}

                {cart.length === 0 ? (
                    <div className="text-center py-8">
                        <Card>
                            <CardBody className={`p-8 ${theme === 'light' ? 'bg-white' : 'bg-neutral-700'}`}>
                                <Typography variant="h5" className={`mb-4 ${theme === 'dark' ? 'text-neutralLight' : 'text-neutralDark'}`}>
                                    {t('cartIsEmpty')}
                                </Typography>
                                <Link to={`/${id}`}>
                                    <Button color={theme === 'light' ? 'blue' : 'gray'} variant="gradient">
                                        {t('backToMenu')}
                                    </Button>
                                </Link>
                            </CardBody>
                        </Card>
                    </div>
                ) : (
                    <div className="w-full space-y-4">
                        {/* Cart Items */}
                        <div className="space-y-4">
                            {cart.map((item) => (
                                <CartItem
                                    key={item.id}
                                    item={{
                                        ...item,
                                        name: getTranslation(item.translations, 'name', item.name),
                                        description: getTranslation(item.translations, 'description', item.description || '')
                                    }}
                                />
                            ))}
                        </div>

                        {/* Order Note */}
                        <div
                            className={`flex items-center justify-between p-4 rounded-lg shadow-md flex-col gap-4
                            ${theme === 'light' ? 'bg-white' : 'bg-neutral-700'} 
                            ${theme === 'light' ? 'text-neutralDark' : 'text-neutralLight'}`}
                        >
                            <Typography variant="h6" className="mb-2 flex justify-start w-full">
                                {t('addNote')}
                            </Typography>

                            <Textarea
                                type="text"
                                onChange={(e) => setNote(e.target.value)}
                                className={`${
                                    theme === 'dark' ? 'text-neutralLight' : 'text-neutralDark h-24'
                                } w-full shadow-lg`}
                                placeholder={t('notePlaceholder')}
                            />
                        </div>

                        <Typography variant="h5" className={`mt-4 text-right flex gap-2 justify-end ${theme === 'dark' ? 'text-neutralLight' : 'text-neutralDark'}`}>
                            {t('total')}: <Price item={
                                {
                                    price: totalPrice,
                                    currencySymbol: locationData?.restaurant?.currencySymbol,
                                    currencySymbolPosition: locationData?.restaurant?.currencySymbolPosition
                                }
                            } />
                        </Typography>

                        <Button
                            size="lg"
                            className="w-full mt-4"
                            color={theme === 'light' ? 'blue' : 'gray'}
                            variant="gradient"
                            onClick={submitOrder}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? t('processingOrder') : t('confirmOrder')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cart;
