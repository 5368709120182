// File: src/components/CartItem.tsx

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, decrementItem, removeItem } from '../store/cartSlice';
import { Typography, IconButton } from '@material-tailwind/react';
import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import { RootState } from '../store';
import { CartItem as CartItemType } from '../types';
import { Translation } from '../types';
import { useTranslation } from 'react-i18next';
import Price from "./Price.tsx";

interface CartItemProps {
    item: CartItemType;
}

const CartItem: React.FC<CartItemProps> = ({ item }) => {
    const dispatch = useDispatch();
    const theme = useSelector((state: RootState) => state.theme.mode);
    const currentLanguage = useSelector((state: RootState) => state.language.current);
    const { t } = useTranslation();

    const handleQuantityChange = (change: number) => {
        if (change > 0) {
            dispatch(addItem(item));
        } else {
            if (item.quantity + change <= 0) {
                dispatch(removeItem(item.id));
            } else {
                dispatch(decrementItem(item.id));
            }
        }
    };

    const currentItemTranslation = item.translations.find((t) => t.language === currentLanguage) || item.translations.find((t) => t.language === 'en');

    const isLight = theme === 'light';

    return (
        <div
            className={`flex justify-between rounded-lg p-4 shadow-xl
                ${isLight ? 'bg-neutralLight text-neutralDark' : 'bg-neutralDark text-neutralLight'} 
                flex-col lg:flex-row gap-4`}
        >
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="flex justify-center items-center mb-4 h-full min-w-64">
                    {item.imageUrl ? (
                        <img
                            src={`${item.imageUrl}`}
                            alt={currentItemTranslation?.name}
                            className={`rounded shadow-lg h-48 w-full lg:w-72 object-cover`}
                        />
                    ) : (
                        <div className={`h-48 rounded bg-gray-200 flex items-center justify-center shadow-lg w-full lg:w-72`}>
                            <Typography variant="body2" className="text-gray-500">
                                {t('noImage')}
                            </Typography>
                        </div>
                    )}
                </div>
                <div className="details flex flex-col justify-between text-left">
                    <Typography variant="h4" className="mb-2">
                        {currentItemTranslation?.name}
                    </Typography>
                    <Typography className="mb-2">{currentItemTranslation?.description}</Typography>
                </div>
            </div>
            <div className="flex items-center justify-between lg:justify-end gap-4 w-full lg:w-auto">
                <Typography variant="h6" className="font-bold">
                    <Price item={{
                        ...item,
                        currencySymbol: item.currencySymbol,
                        currencySymbolPosition: item.currencySymbolPosition,
                        price: (parseFloat(item.price) * item.quantity).toFixed(2)
                    }} />
                </Typography>

                <div className="flex items-center gap-2">
                    <IconButton
                        variant="text"
                        size="sm"
                        onClick={() => handleQuantityChange(-1)}
                        className={`text-neutralLight ${isLight ? 'hover:text-neutralDark' : 'hover:text-neutralLight'}`}
                    >
                        {item.quantity === 1 ? (
                            <TrashIcon className="h-4 w-4" />
                        ) : (
                            <MinusIcon className="h-4 w-4" />
                        )}
                    </IconButton>

                    <Typography variant="h6" className="w-8 text-center">
                        {item.quantity}
                    </Typography>

                    <IconButton
                        variant="text"
                        size="sm"
                        onClick={() => handleQuantityChange(1)}
                        className={`text-neutralLight ${isLight ? 'hover:text-neutralDark' : 'hover:text-neutralLight'}`}
                    >
                        <PlusIcon className="h-4 w-4" />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default CartItem;
