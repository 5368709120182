// File: src/utils/payment-method.utils.ts

import { ChipProps } from '@material-tailwind/react';
import { PaymentMethod } from '../types';
import { CreditCardIcon, BanknotesIcon } from '@heroicons/react/24/solid';
import React from 'react';

export const paymentMethodBadgeColor = (method: PaymentMethod): ChipProps['color'] => {
    switch (method) {
        case 'cash':
            return 'green';
        case 'card':
            return 'blue';
        default:
            return 'gray';
    }
};

export const paymentMethodIcon = (method: PaymentMethod): React.ReactNode => {
    switch (method) {
        case 'cash':
            return <BanknotesIcon className="h-5 w-5 mr-2" />;
        case 'card':
            return <CreditCardIcon className="h-5 w-5 mr-2" />;
        default:
            return null;
    }
};

export const paymentMethodOptions = [
    { label: 'Cash', value: 'cash', color: 'green' },
    { label: 'Card', value: 'card', color: 'blue' },
] as const;
